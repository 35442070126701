import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AuthGuard } from './providers/AuthGuard';
import { RouteDataProvider } from './providers/RouteDataProvider';
import { CoreCommonModule } from '@core/common.module';
import { TranslateModule } from '@ngx-translate/core';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FieldErrorDisplayComponent } from './components/field-error-display/field-error-display.component';
import { AlphaNumericDirective } from './directive/alphanumeric.directive';
import { AlphaNumericSpaceDirective } from './directive/alphanumricspace.directive';
import { NumericDirective } from './directive/numeric.directive';
import { AlphabetsDirective } from './directive/onlyalphabets.directive';
import { OnlydecimalDirective } from './directive/onlydecimals.directive';
import { OnlynumberDirective } from './directive/onlynumber.directive';
import { PhoneMaskDirective } from './directive/phonemask.directive';



@NgModule({
  declarations: [
    FieldErrorDisplayComponent,
    OnlynumberDirective,
    OnlydecimalDirective,
    AlphaNumericDirective,
    AlphaNumericSpaceDirective,
    NumericDirective,
    AlphabetsDirective,
    PhoneMaskDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ContentHeaderModule, 
    TranslateModule, 
    CoreCommonModule,
    FieldErrorDisplayComponent,
    OnlynumberDirective,
    OnlydecimalDirective,
    AlphaNumericDirective,
    AlphaNumericSpaceDirective,
    NumericDirective,
    AlphabetsDirective,
    PhoneMaskDirective
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [RouteDataProvider, AuthGuard, DatePipe]
    };
  }
}
