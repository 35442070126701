// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   hmr: false,
//   apiUrl: 'http://api.eva.local',
//   backend: {
//     baseURL:"http://api.eva.local",
//     uploadURL: "http://ui.eva.local/",
//     path: "E:/eva/UI/uploads/"
//   }
// };

export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'http://evadev-api.trendway.co.in',
  backend: {
    baseURL:'http://evadev-api.trendway.co.in',
    uploadURL: 'http://eva-dev.trendway.co.in',
    path: "E:/eva/UI/uploads/"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
